<template>
  <div id="box">
    <div id="index_boxs" class="box">
      <div class="header">
        <!-- <img src="../../assets/img/login_logo.png" alt=""> -->
        <i class="zp_logo"/>
        <b>视商</b>
        管理系统
      </div>
      <div v-if="codeFalge" class="form-box">
        <el-form ref="ruleForm2" :model="loginForm" :rules="rulesForm" status-icon class="demo-ruleForm">
          <el-form-item prop="username">
            <el-input id="account" v-model="loginForm.account" placeholder="用户名" class="input_user" auto-complete="off" @keyup.enter.native="submitForm('ruleForm2')" />
          </el-form-item>
          <el-form-item prop="password" class="password">
            <el-input id="password" v-model="loginForm.password" placeholder="密码" class="input_user" type="password" auto-complete="new-password" @keyup.enter.native="submitForm('ruleForm2')"/>
          </el-form-item>
          <el-checkbox-group v-model="checkList" class="remember">
            <el-checkbox label="username">记住用户名</el-checkbox>
            <el-checkbox label="password">记住密码</el-checkbox>
          </el-checkbox-group>
          <el-form-item>
            <el-button id="submit" class="submit" type="primary" @click="submitForm('ruleForm2')">登录</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div v-else class="from-code">
        <el-form class="tishifrom">
          <el-form-item label="您正在使用手机短信验证身份" class="iphonenum" prop="iphoneNum"/>
        </el-form>
        <el-form ref="iphoneNum" :model="form" class="demo-ruleForm">

          <el-form-item ref="iphoneNum" label="验证手机" class="iphonenonum" >
            <el-input v-model="form.iphoneNum" class="input_user iphonenonum" disabled />
          </el-form-item>
        </el-form>
        <el-form ref="iphoneCode" :rules="rules2" :model="form">
          <el-form-item label="验证码" prop="iphoneCode" class="codebtn">
            <el-input v-model="form.iphoneCode" maxlength="6" class="input_user" placeholder="6位数字验证码" />
            <el-button v-if="ipNumFalge" type="button" @click="submitipNum">获取验证码</el-button>
            <el-button v-else type="button" >{{ ipTime }}秒后重新获取</el-button>
          </el-form-item>
          <el-button id="submit" class="submit subcode" type="primary" @click="submitCode('iphoneCode',$event)">确定</el-button>
        </el-form>

      </div>
    </div>
  </div>
</template>
<script>
import { getRedirect, setNeedValid, removeToken, removeTimeTag } from '@/utils/auth' // 验权
import { doSendSms, doValid } from '@/api/auth'
// import store from '@/store'
export default {
  name: 'Index',
  data() {
    var validateUsername = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入账号'))
        return
      }
      callback()
    }
    var validatePassword = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入密码'))
        return
      }
      callback()
    }
    return {
      checkList: [],
      loginForm: {
        account: '',
        password: ''
      },
      rulesForm: {
        account: [
          { validator: validateUsername, trigger: 'blur' }
        ],
        password: [
          { validator: validatePassword, trigger: 'blur' }
        ]
      },
      form: {
        iphoneNum: '',
        iphoneCode: ''
      },
      ipNumFalge: true,
      ipTime: 60,
      rules2: {
        iphoneCode: [
          { required: true, message: '验证码不能为空', trigger: 'blur' }
          // { min: 3, max: 5, message: '请输入正确的验证码', trigger: 'blur' }
        ]
      },
      timeId: null,
      codeFalge: true,
      redirectType: '',
      redirectUrl: ''
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        this.redirect = route.query && route.query.redirect
      },
      immediate: true
    }
  },
  created() {
    if (localStorage.getItem('username')) {
      this.checkList.push('username')
      this.loginForm.account = localStorage.getItem('username')
    }
    if (localStorage.getItem('password')) {
      this.checkList.push('password')
      this.loginForm.password = localStorage.getItem('password')
    }
    // this.goPAGE()
    removeToken()
    removeTimeTag()
  },
  beforeDestroy() {
    if (this.timeId) {
      clearInterval(this.timeId)
    }
  },
  methods: {
    // 判断是否移动端
    // goPAGE() {
    //   if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
    //     require('../../assets/css/move.css')
    //   } else {
    //     require('../../assets/css/pc.css')
    //   }
    // },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$store.dispatch('Login', this.loginForm).then((response) => {
            const that = this
            // 登录的时候记住帐号和密码
            for (let i = 0; i < that.checkList.length; i++) {
              if (that.checkList.length === 2) {
                if (that.checkList[i] === 'username') {
                  localStorage.setItem('username', that.loginForm.account)
                }
                if (that.checkList[i] === 'password') {
                  localStorage.setItem('password', that.loginForm.password)
                }
              }
              if (that.checkList.length === 1) {
                if (that.checkList[i] === 'username') {
                  localStorage.setItem('username', that.loginForm.account)
                } else {
                  localStorage.removeItem('username')
                }
                if (that.checkList[i] === 'password') {
                  localStorage.setItem('password', that.loginForm.password)
                } else {
                  localStorage.removeItem('password')
                }
              }
            }
            if (that.checkList.length === 0) {
              localStorage.removeItem('username')
              localStorage.removeItem('password')
            }
            // 用户跳转
            const newpath = getRedirect()
            that.$router.push({ path: newpath || '/' })
            // that.$router.push({ path: newpath || '/' })
            // const newpath = getRedirect()
            // console.log('index.vue:' + newpath)
            // that.$router.push({ path: newpath || '/home/index' })
          }).catch(() => {
            // this.loading = false
          })
        } else {
          return false
        }
      })
    },
    showPwd() {
      if (this.pwdType === 'password') {
        this.pwdType = ''
      } else {
        this.pwdType = 'password'
      }
    }
  }
}
</script>

<style scoped>
#box{
  position: relative;
  width: 100%;
  height: 100%;
  background: linear-gradient(#27C8FF, #64E8FF)
}
.box{
  width: 187.5rem;
  height: 400px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.24);
  position: absolute;
  right: 20%;
  /* top: calc(50% - 200px) */
  top: 25%
}
.header{
  width: 305px;
  height: 43px;
  line-height: 43px;
  margin: auto;
  padding: 50px 0 20px;
  font-size: 24px
}
.form-box{
  width: 305px;
  margin: auto;
  padding: 20px 0 20px;
  font-size: 16px
}
.zp_logo{
     display: inline-block;
    width:85px;
    height:43px;
    margin-right: 10px;
    vertical-align: middle;
    /* background: url("../../assets/img/login_logo.png") left center no-repeat; */
}
.el-input{
  width: 285px;
}
.input_user>>>.el-input__inner{
  height: 40px  !important;
  background-color: #fff6d9;
}
.submit{
  width: 300px;
  height: 40px;
  border-radius: 5px;
  margin-top: 20px
}
.remember>>>.el-checkbox__input.is-checked+.el-checkbox__label,.remember>>>.el-checkbox__label{
  color: #000;
  font-size: 16px
}
.el-button{
  line-height: 0;
  font-size: 24px
}
.el-button--primary:focus,.el-button--primary:active{
  background-color:#f74b4e;
  border-color:#f74b4e;
}

/*  */
.codebtn  {
  position: relative;

}
.codebtn .el-button {
  /* padding: 8px 14px;
  background-color: #f40; */
  position: absolute;
  color: #f40;
  font-size: 14px;
  border: none;
  top:2px;
  right: 20px;
  padding: 0;
  height: 36px;
  background-color: #fff6d9;
}
.from {
   margin: auto;
  padding: 20px 0 20px;
  font-size: 16px
}
.from-code .el-input {
  width: 236px;
}
/* .subcode {
  margin-left: 73px;
} */
.tishifrom .el-form-item {
  margin-bottom: 10px;
}
.iphonenonum>>>.el-input__inner {
  background-color:#fff;
  border: none;
}
.iphonenonum>>>.el-input.is-disabled .el-input__inner {
  color: #606266;
  cursor:default;
}

/*  */
.from-code {

  width: 305px;
  margin: auto;
  padding: 20px 0 20px;
  font-size: 16px;
}
@media screen and (max-width: 799px) {
  .subcode {
    margin-top: 0;
  }
}
</style>
